<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu :admin-title="adminTitle" />
    <div class="flex">
      <!-- BEGIN: Simple Menu -->
      <nav class="side-nav side-nav--simple">
        <!-- BEGIN: Logo -->
        <router-link
          to="/simple-menu"
          tag="a"
          class="intro-x flex items-center"
        >
          <img
            :alt="adminTitle"
            :title="adminTitle"
            class="side-nav__logo"
            src="@/assets/images/logo.svg"
          />
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <Tippy
                tag="a"
                :content="menu.title"
                :options="{
                  placement: 'left'
                }"
                href="javascript:;"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router)"
              >
                <div class="side-menu__icon">
                  <FontAwesome
                    v-if="menu.icon.startsWith('fa-')"
                    class="w-6 h-6 m-auto"
                    :icon="menu.icon.substr(3)"
                  />
                  <img
                    v-else-if="menu.icon.includes('/')"
                    :src="menu.icon"
                    class="m-auto"
                  />
                  <component v-else :is="menu.icon" class="m-auto" />
                </div>
                <div class="side-menu__title">
                  <span class="side-menu__title-text">{{ menu.title }}</span>
                  <ChevronDownIcon
                    v-if="$h.isset(menu.subNodes)"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  />
                </div>
              </Tippy>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="$h.isset(menu.subNodes) && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subNodes"
                    :key="subMenuKey"
                  >
                    <Tippy
                      tag="a"
                      :content="subMenu.title"
                      :options="{
                        placement: 'left'
                      }"
                      href="javascript:;"
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router)"
                    >
                      <div class="side-menu__icon">
                        <FontAwesome
                          v-if="subMenu.icon.startsWith('fa-')"
                          class="w-6 h-6 m-auto"
                          :icon="subMenu.icon.substr(3)"
                        />
                        <img
                          v-else-if="subMenu.icon.includes('/')"
                          :src="subMenu.icon"
                          class="m-auto"
                        />
                        <component
                          v-else-if="subMenu.icon"
                          :is="subMenu.icon"
                          class="m-auto"
                        />
                        <ActivityIcon v-else class="m-auto" />
                      </div>
                      <div class="side-menu__title">
                        <span class="side-menu__title-text">{{
                          subMenu.title
                        }}</span>
                        <ChevronDownIcon
                          v-if="$h.isset(subMenu.subNodes)"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        />
                      </div>
                    </Tippy>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul
                        v-if="
                          $h.isset(subMenu.subNodes) && subMenu.activeDropdown
                        "
                      >
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subNodes"
                          :key="lastSubMenuKey"
                        >
                          <Tippy
                            tag="a"
                            :content="lastSubMenu.title"
                            :options="{
                              placement: 'left'
                            }"
                            href="javascript:;"
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router)"
                          >
                            <div class="side-menu__icon">
                              <FontAwesome
                                v-if="lastSubMenu.icon.startsWith('fa-')"
                                class="w-6 h-6 m-auto"
                                :icon="lastSubMenu.icon.substr(3)"
                              />
                              <img
                                v-else-if="lastSubMenu.icon.includes('/')"
                                :src="lastSubMenu.icon"
                                class="m-auto"
                              />
                              <component
                                v-else-if="lastSubMenu.icon"
                                :is="lastSubMenu.icon"
                                class="m-auto"
                              />
                              <ActivityIcon v-else class="m-auto" />
                            </div>
                            <div class="side-menu__title">
                              <span class="side-menu__title-text">{{
                                lastSubMenu.title
                              }}</span>
                            </div>
                          </Tippy>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Simple Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
    <footer
      class="flex justify-center text-white dark:text-white text-xs sm:text-sm mt-4"
    >
      Copyright&nbsp;&copy;2023&ensp;
      <a href="//www.cloudfun.com.tw" target="_blank" rel="noopener noreferrer"
        >CloudFun</a
      >&nbsp;Co.,Ltd.&ensp; All&nbsp;Rights&nbsp;Reserved&ensp;
      版權所有，盜用必究
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { linkTo, enter, leave } from "@/layouts/side-menu";
import context from "@cloudfun/core";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu
    // DarkModeSwitcher,
  },
  setup() {
    const application = context.current;
    const policy = application?.policy;
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const simpleMenu = computed(() => policy?.makeMenu("/simple-menu") || []);
    const configuration = ref({ AdminTitle: "蕭氏貿易系統管理後台" });

    watch(
      computed(() => route.fullPath),
      () => {
        formattedMenu.value = $h.toRaw(simpleMenu.value);
      }
    );

    const adminTitle = computed(
      () =>
        application?.model.state.configuration.value.AdminTitle ||
        "蕭氏貿易系統管理後台"
    );

    onMounted(async () => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(simpleMenu.value);
      if (application) application.model.dispatch("configuration/read");
    });

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      configuration,
      adminTitle
    };
  }
});
</script>
